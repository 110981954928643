import React from 'react';
import styled from 'styled-components';
import NavBarBasic from '../components/NavBar/NavBarBasic';
import { ContainerPageBlank, ContentPageBlank, H2PageBlank, H3PageBlank, Li, ParagraphPageBlank, StyledLink, Ul, WraperPageBlank } from '../components/StyledComponents/StyledComponents';

const TermsAndConditions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <ContentPageBlank>
      <NavBarBasic />
      <WraperPageBlank>
        <ContainerPageBlank>
          <H2PageBlank>Términos y Condiciones de Uso</H2PageBlank>
          <H3PageBlank>INFORMACIÓN RELEVANTE</H3PageBlank>
          <ParagraphPageBlank>
            Es requisito necesario para la adquisición de los productos que se ofrecen en esta aplicación, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todas los productos  que son ofrecidos por nuestra aplicación pudieran ser creados, cobrados, enviados o presentados por una página web tercera y en tal caso estarían sujetos a sus propios Términos y Condiciones. En algunos casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento. PetMimo no asume la responsabilidad en caso de que entregue dicha clave a terceros.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            Todas las compras y transacciones que se realicen por medio de esta aplicación, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación del stock y disponibilidad de producto, validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos se puede requerir una verificación por medio de correo electrónico.
          </ParagraphPageBlank>
          <H3PageBlank>LICENCIA</H3PageBlank>
          <ParagraphPageBlank>
            PetMimo  concede licencia para que los usuarios utilicen  los productos que son vendidos en esta aplicación de acuerdo a los Términos y Condiciones que se describen en este documento.
          </ParagraphPageBlank>
          <H3PageBlank>USO NO AUTORIZADO</H3PageBlank>
          <ParagraphPageBlank>
            En caso de que aplique (para venta de software, plantillas, u otro producto de diseño y programación) usted no puede colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.
          </ParagraphPageBlank>
          <H3PageBlank>PROPIEDAD</H3PageBlank>
          <ParagraphPageBlank>
            Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. Todos los productos son propiedad  de los proveedores del contenido. En caso de que no se especifique lo contrario, nuestros productos se proporcionan  sin ningún tipo de garantía, expresa o implícita. En ningún caso esta compañía será  responsables de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.
          </ParagraphPageBlank>
          <H3PageBlank>EXPLOTACION Y ABUSO SEXUAL INFANTIL</H3PageBlank>
          <ParagraphPageBlank>
            Durante el uso de los productos y servicios de la aplicación PetMimo, usted no podrá crear, cargar o distribuir contenido que facilite la explotación o el abuso sexual de niños. Esto incluye, aunque no está limitado a: interacción inapropiada dirigida a un niño/niña, contacto sexual y/o intercambio de imágenes sexuales con niños, sexualización de un menor, amenazar o chantajear niños, publicidad relacionada a explotación sexual de niños/niñas.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            En caso de violar esta norma, su cuenta será inmediatamente removida o suspendida de nuestra plataforma.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            Si usted detecta este tipo de contenido en PetMimo, la app le brinda opciones para reportar la publicación. También puede reportar cualquier incidente en este correo electrónico: reach@petmimo.com, o a través del siguiente teléfono: +598 92 739 068
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            Si cree que un niño o niña está en peligro o ha sido objeto de abuso, explotación o trata, comuníquese con las autoridades locales y también con La Línea Azul de INAU, cuyos datos se encuentran en la siguiente página:
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            <StyledLink href="https://www.inau.gub.uy/content_page/item/512-linea-azul-denuncias" target="_blank" rel="noopener noreferrer">
              https://www.inau.gub.uy/content_page/item/512-linea-azul-denuncias
            </StyledLink>
          </ParagraphPageBlank>

          <H3PageBlank>POLÍTICA DE REEMBOLSO Y GARANTÍA</H3PageBlank>
          <ParagraphPageBlank>
            En el caso de productos que sean  mercancías irrevocables no-tangibles, no realizamos reembolsos después de que se envíe el producto, usted tiene la responsabilidad de entender antes de comprarlo.  Le pedimos que lea cuidadosamente antes de comprarlo. Hacemos solamente excepciones con esta regla cuando la descripción no se ajusta al producto. Hay algunos productos que pudieran tener garantía y posibilidad de reembolso, pero esto será especificado al comprar el producto. En tales casos la garantía solo cubrirá fallas de fábrica y sólo se hará efectiva cuando el producto se haya usado correctamente. La garantía no cubre averías o daños ocasionados por uso indebido. Los términos de la garantía están asociados a fallas de fabricación y funcionamiento en condiciones normales de los productos y sólo se harán efectivos estos términos si el producto ha sido usado correctamente. Esto incluye:
          </ParagraphPageBlank>
          <Ul>
            <Li>De acuerdo a las especificaciones técnicas indicadas para cada producto.</Li>
            <Li>En condiciones ambientales acorde con las especificaciones indicadas por el fabricante.</Li>
            <Li>En uso específico para la función con que fue diseñado de fábrica.</Li>
            <Li>En condiciones de operación eléctricas acorde con las especificaciones y tolerancias indicadas.</Li>
          </Ul>
          <H3PageBlank>COMPROBACIÓN ANTIFRAUDE</H3PageBlank>
          <ParagraphPageBlank>
            La compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por más tiempo para una investigación más rigurosa, para evitar transacciones fraudulentas.
          </ParagraphPageBlank>
          <H3PageBlank>PRIVACIDAD</H3PageBlank>
          <ParagraphPageBlank>
            PetMimo (https://www.petmimo.com) garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por el usuario no serán entregados a terceros, salvo en cumplimiento a una orden judicial o requerimientos legales.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            La suscripción a boletines de correos electrónicos publicitarios es voluntaria.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            PetMimo se reserva los derechos de cambiar o de modificar estos términos sin previo aviso.
          </ParagraphPageBlank>
        </ContainerPageBlank>
      </WraperPageBlank>
    </ContentPageBlank>
  );
};

export default TermsAndConditions;
